import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  chakra,
  SimpleGrid,
  Heading,
  Icon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  UnorderedList,
  ListItem,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { FaUsers, FaGlobeAmericas } from "react-icons/fa";
import { MdRepeatOn } from "react-icons/md";
import { TbDeviceMobileMessage, TbMessage2Share } from "react-icons/tb";
import { BsFillSave2Fill } from "react-icons/bs";
import axios from "axios";
// FaUsers import { FaBeer } from "@react-icons/all-files/fa/FaBeer"; BsFillSave2Fill FaGlobeAmericas

function Dashboard() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isopenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [topUserCountries, setCountries] = useState([]);
  const [topMessages, setCountriesMessages] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalMessages, setTotalMessages] = useState(0);
  const [isUsers, setIsUsers] = useState(false);
  const toast = useToast();
  const [data, setData] = useState({});
  const authData = localStorage.getItem("key-rampage");
  useEffect(() => {
    if (!authData) {
      window.open("/", "_self");
      if (authData?.length === 0) {
        toast({
          title: "Unautharized",
          description: "Please loggin to continue",
          status: "error",
          duration: 8000,
          isClosable: false,
        });
        window.open("/", "_self");
      }
    } else {
      fetchData();
      setIsAuth(true);
      setIsLoading(true);
    }
  }, []);
  useEffect(() => {
    if (data) {
      let temp = data?.userByCountry || [];
      let tempMessages = data?.messagesByContry || [];
      let tempData = [];
      let tempDataMsg = [];
      let totalUsr = 0;
      let totalMsg = 0;
      for (let i = 0; i < temp.length; i++) {
        if (i < 6) {
          tempData.push(temp[i]);
          tempDataMsg.push(tempMessages[i]);
        }
        totalUsr += temp[i].users;
        // totalMsg += tempMessages[i]?.messages;
      }
      for (let i = 0; i < tempMessages.length; i++) {
        totalMsg += tempMessages[i]?.messages;
      }
      // //("total messages:", totalMsg);
      setTotalUsers(totalUsr);
      setTotalMessages(totalMsg);
      setCountries(tempData);
      setCountriesMessages(tempDataMsg);
      //("data", tempData);
    }
  }, [data]);
  const fetchData = async () => {
    try {
      var config = {
        method: "get",
        url: "https://milo-api.digest.ai/api/dashboard",
        headers: {
          Authorization: `Bearer ${authData}`,
        },
      };

      axios(config)
        .then(function (response) {
          setData(response.data);
          setIsLoading(false);
        })
        .catch(function (error) {
          localStorage.setItem("key-rampage", "");
          toast({
            title: "error.",
            description: "Please login agian.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          window.open("/", "_self");
          setIsLoading(false);
          setTimeout(() => {
            toast({
              title: "error.",
              description: "Please login agian.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }, [3000]);
        });
    } catch (error) {
      toast({
        title: "error.",
        description: "Please login agian.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      window.open("/", "_self");
      setIsLoading(false);
    }
  };
  const showData = (value) => {
    if (value === "users") {
      setIsUsers(true);
    } else {
      //("enterd");
      setIsUsers(false);
    }
    onOpenModal();
  };
  return (
    <>
      {isAuth && (
        <chakra.div w="100%" h="fit-content" paddingBottom="100px" bg="#281e52">
          {/*  */}
          <Modal
            isOpen={isopenModal}
            onClose={onCloseModal}
            isCentered
            size="md"
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader
                textAlign="center"
                borderBottom="0.5px solid lightgray"
              >
                {isUsers ? (
                  <b>Global User Index</b>
                ) : (
                  <b>Global Messaging Index</b>
                )}
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody maxH="50vh" overflowY="scroll" bg="#281e52">
                <Flex
                  h="fit-content"
                  w="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  {isUsers ? (
                    <SimpleGrid columns="2" gap={8} mt="12px">
                      {data?.userByCountry?.map((value, index) => (
                        <Box
                          w={{ sm: "120px", md: "160px" }}
                          h="fit-content"
                          bg="white"
                          borderRadius="8px"
                          display="flex"
                          flexDirection="column"
                          key={index}
                        >
                          <Heading
                            size="xs"
                            paddingTop="4px"
                            textAlign="center"
                            paddingBottom="6px"
                            borderBottom="0.5px solid lightgray"
                            bg="#C5DBFE"
                            borderTopRadius="8px"
                            color="#02278A "
                          >
                            {value.country}
                          </Heading>
                          <Box ml="8px" mt="4px" fontSize="smaller">
                            <p>
                              <b>Total Users: {value.users}</b>{" "}
                            </p>
                            <p>
                              <b>Percentage: {value.percentage}% </b>{" "}
                            </p>
                          </Box>
                        </Box>
                      ))}
                    </SimpleGrid>
                  ) : (
                    <SimpleGrid columns="2" gap={8} mt="12px">
                      {data?.messagesByContry?.map((value, index) => (
                        <Box
                          w={{ sm: "120px", md: "160px" }}
                          h="fit-content"
                          bg="white"
                          borderRadius="8px"
                          display="flex"
                          flexDirection="column"
                          key={index}
                        >
                          <Heading
                            size="xs"
                            paddingTop="4px"
                            textAlign="center"
                            paddingBottom="6px"
                            borderBottom="0.5px solid lightgray"
                            bg="#FAE69E"
                            borderTopRadius="8px"
                            color="#02278A "
                          >
                            {value.country}
                          </Heading>
                          <Box ml="8px" mt="4px" fontSize="smaller">
                            <p>
                              <b>Total Messages: {value?.messages}</b>{" "}
                            </p>
                            <p>
                              <b>Percentage: {value.percentage}% </b>{" "}
                            </p>
                          </Box>
                        </Box>
                      ))}
                    </SimpleGrid>
                  )}
                </Flex>
              </ModalBody>

              <ModalFooter>
                <Button
                  bg="#281e52"
                  mr={3}
                  onClick={onCloseModal}
                  color="white"
                >
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Modal isOpen={isOpen} onClose={onClose} isCentered size="sm">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader
                textAlign="center"
                borderBottom="0.5px solid lightgray"
              >
                Coming soon..
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Flex
                  h="fit-content"
                  w="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <UnorderedList>
                    <ListItem>Line graphs.</ListItem>
                    <ListItem>Bar graphs and Pie charts.</ListItem>
                    <ListItem>
                      Unique data representation for each module.
                    </ListItem>
                    <ListItem>Over all data representation.</ListItem>
                    <ListItem>
                      Sugestions using custom <b>AI algorithms</b>.
                    </ListItem>
                  </UnorderedList>
                </Flex>
              </ModalBody>

              <ModalFooter>
                <Button bg="#281e52" mr={3} onClick={onClose} color="white">
                  Close
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Flex
            w="100%"
            h="5vh"
            boxShadow={`rgba(0, 0, 0, 0.35) 0px 5px 15px`}
            alignItems="center"
            justifyContent="center"
            color="whiteAlpha.700"
          >
            <Box mr="5px">___</Box>
            <Heading color="whiteAlpha.700" fontSize="lg">
              <p>Data Analytics</p>
            </Heading>
            <Box ml="5px">___</Box>
          </Flex>

          <Flex
            w="100%"
            alignItems="center"
            justifyContent="center"
            mt="2vh"
            paddingBottom="10vh"
          >
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 2 }}
              gap={10}
              width="fit-content"
              borderRadius={{ base: "28px", md: "45px" }}
              spacingX={{ base: "240px", md: "40px" }}
              overflowX={{ base: "scroll", md: "hidden" }}
              // box-shadow: ;
            >
              {/* bx-1 */}
              <Box
                w={{ base: "300px", sm: "300px", md: "400px" }}
                height="500px"
                bg="whiteAlpha.800"
                borderRadius="20px"
                margin="10px"
                boxShadow={`rgba(0, 0, 0, 0.35) 0px 5px 15px`}
                position="relative"
              >
                <Flex
                  h="20%"
                  w="100%"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  bg="white"
                  color="black"
                  boxShadow={`rgba(0, 0, 0, 0.35) 0px 5px 15px`}
                  borderTopRadius="20px"
                >
                  <Icon
                    as={FaGlobeAmericas}
                    color="#727FF9"
                    fontSize="4xl"
                    marginBottom="8px"
                  />
                  <Heading size="md"> Global User Index</Heading>
                </Flex>
                {isLoading ? (
                  <Flex
                    h="300px"
                    w="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="#281e52"
                      size="xl"
                    />
                  </Flex>
                ) : (
                  <Flex
                    mt="12px"
                    flexDirection="column"
                    w="100%"
                    alignItems="center"
                  >
                    <Heading size="sm">
                      Top 6 countries amongst{" "}
                      <b>
                        <u>{totalUsers}</u>
                      </b>{" "}
                      users
                    </Heading>
                    <SimpleGrid columns="2" gap={4} mt="12px">
                      {topUserCountries.map((value, index) => (
                        <Box
                          w={{ sm: "120px", md: "160px" }}
                          h="80px"
                          bg="white"
                          borderRadius="8px"
                          display="flex"
                          flexDirection="column"
                          key={index}
                        >
                          <Heading
                            size="xs"
                            paddingTop="4px"
                            textAlign="center"
                            paddingBottom="6px"
                            borderBottom="0.5px solid lightgray"
                            bg="#C5DBFE"
                            borderTopRadius="8px"
                            color="#02278A "
                          >
                            {value.country}
                          </Heading>
                          <Box ml="8px" mt="4px" fontSize="smaller">
                            <p>
                              <b>Total Users: {value.users}</b>{" "}
                            </p>
                            <p>
                              <b>Percentage: {value.percentage}% </b>{" "}
                            </p>
                          </Box>
                        </Box>
                      ))}
                    </SimpleGrid>
                  </Flex>
                )}

                <Box
                  position="absolute"
                  bottom="5"
                  w="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    w="90%"
                    color="white"
                    bg="#281e52"
                    onClick={() => {
                      showData("users");
                    }}
                  >
                    {" "}
                    Show More{" "}
                  </Button>
                </Box>
              </Box>
              {/* bx-2 */}
              <Box
                w={{ base: "300px", sm: "300px", md: "400px" }}
                height="500px"
                bg="whiteAlpha.800"
                borderRadius="20px"
                margin="10px"
                boxShadow={`rgba(0, 0, 0, 0.35) 0px 5px 15px`}
                position="relative"
              >
                <Flex
                  h="20%"
                  w="100%"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  bg="white"
                  color="black"
                  boxShadow={`rgba(0, 0, 0, 0.35) 0px 5px 15px`}
                  borderTopRadius="20px"
                >
                  <Icon
                    as={FaGlobeAmericas}
                    color="#F99772"
                    fontSize="4xl"
                    marginBottom="8px"
                  />
                  <Heading size="md">Global Messaging Index</Heading>
                </Flex>
                {isLoading ? (
                  <Flex
                    h="300px"
                    w="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="#281e52"
                      size="xl"
                    />
                  </Flex>
                ) : (
                  <Flex
                    mt="12px"
                    flexDirection="column"
                    w="100%"
                    alignItems="center"
                  >
                    <Heading size="sm" textAlign="center">
                      Top 6 countries amongst{" "}
                      <b>
                        <u>{totalMessages}</u>
                      </b>{" "}
                      messages
                    </Heading>
                    <SimpleGrid columns="2" gap={4} mt="12px">
                      {topMessages.map((value, index) => (
                        <Box
                          w={{ sm: "120px", md: "160px" }}
                          h="80px"
                          bg="white"
                          borderRadius="8px"
                          display="flex"
                          flexDirection="column"
                          key={index}
                        >
                          <Heading
                            size="xs"
                            paddingTop="4px"
                            textAlign="center"
                            paddingBottom="6px"
                            borderBottom="0.5px solid lightgray"
                            bg="#FAE69E"
                            borderTopRadius="8px"
                            color="#02278A "
                          >
                            {value?.country}
                          </Heading>
                          <Box ml="8px" mt="4px" fontSize="smaller">
                            <p>
                              <b>Total Messages: {value?.messages}</b>{" "}
                            </p>
                            <p>
                              <b>Percentage: {value?.percentage}% </b>{" "}
                            </p>
                          </Box>
                        </Box>
                      ))}
                    </SimpleGrid>
                  </Flex>
                )}

                <Box
                  position="absolute"
                  bottom="5"
                  w="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    w="90%"
                    color="white"
                    bg="#281e52"
                    onClick={() => {
                      showData("msg");
                    }}
                  >
                    {" "}
                    Show More{" "}
                  </Button>
                </Box>
              </Box>

              {/* bx-3 */}
              <Box
                w={{ base: "300px", sm: "300px", md: "400px" }}
                height="500px"
                bg="whiteAlpha.800"
                borderRadius="20px"
                margin="10px"
                boxShadow={`rgba(0, 0, 0, 0.35) 0px 5px 15px`}
                position="relative"
              >
                <Flex
                  h="20%"
                  w="100%"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  bg="white"
                  color="black"
                  boxShadow={`rgba(0, 0, 0, 0.35) 0px 5px 15px`}
                  borderTopRadius="20px"
                >
                  <Icon
                    as={FaUsers}
                    color="#6FC700"
                    fontSize="4xl"
                    marginBottom="8px"
                  />
                  <Heading size="md">Active Users</Heading>
                </Flex>
                {isLoading ? (
                  <Flex
                    h="300px"
                    w="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="#281e52"
                      size="xl"
                    />
                  </Flex>
                ) : (
                  <Flex
                    flexDirection="column"
                    gap={{ base: "20px", md: 5 }}
                    margin="20px"
                    mt="30px"
                  >
                    <Flex w="100%" justifyContent="center" gap={5}>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading size="xs" position="absolute" top="2">
                          Active Today
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#C4F4A5"
                          borderRadius="2px"
                        >
                          {data?.activeUsers.today}
                        </Heading>
                      </Box>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Active current</p>
                          <p>week</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#C4F4A5"
                          borderRadius="2px"
                        >
                          {data?.activeUsers.currentWeek}
                        </Heading>
                      </Box>
                    </Flex>

                    {/* Row 2 */}
                    <Flex w="100%" justifyContent="center" gap={5}>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Active current</p>
                          <p>month</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#C4F4A5"
                          borderRadius="2px"
                        >
                          {data?.activeUsers.currentMonth}
                        </Heading>
                      </Box>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Active last</p>
                          <p>month</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#C4F4A5"
                          borderRadius="2px"
                        >
                          {data?.activeUsers.lastmonth}
                        </Heading>
                      </Box>
                    </Flex>

                    {/* row 3 */}
                    <Flex w="100%" justifyContent="center">
                      <Box
                        w="200px"
                        h="90px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Total active users</p>
                          <p>till date</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#C4F4A5"
                          borderRadius="2px"
                        >
                          {data?.activeUsers.total}
                        </Heading>
                      </Box>
                    </Flex>
                  </Flex>
                )}

                <Box
                  position="absolute"
                  bottom="5"
                  w="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    w="90%"
                    color="white"
                    bg="#281e52"
                    onClick={() => {
                      onOpen();
                    }}
                  >
                    {" "}
                    Know More{" "}
                  </Button>
                </Box>
              </Box>
              {/* bx-4 */}
              <Box
                w={{ base: "300px", sm: "300px", md: "400px" }}
                height="500px"
                bg="whiteAlpha.800"
                borderRadius="20px"
                margin="10px"
                boxShadow={`rgba(0, 0, 0, 0.35) 0px 5px 15px`}
                position="relative"
              >
                <Flex
                  h="20%"
                  w="100%"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  bg="white"
                  color="black"
                  boxShadow={`rgba(0, 0, 0, 0.35) 0px 5px 15px`}
                  borderTopRadius="20px"
                >
                  <Icon
                    as={BsFillSave2Fill}
                    color="#054a18"
                    fontSize="4xl"
                    marginBottom="8px"
                  />
                  <Heading size="md">SignUps</Heading>
                </Flex>
                {isLoading ? (
                  <Flex
                    h="300px"
                    w="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="#281e52"
                      size="xl"
                    />
                  </Flex>
                ) : (
                  <Flex
                    flexDirection="column"
                    gap={{ base: "20px", md: 5 }}
                    margin="20px"
                    mt="30px"
                  >
                    <Flex w="100%" justifyContent="center" gap={5}>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Users signed up</p>
                          <p>today</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#C4F4A5"
                          borderRadius="2px"
                        >
                          {data?.signUps.today}
                        </Heading>
                      </Box>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Users signed up in</p>
                          <p>current week</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#C4F4A5"
                          borderRadius="2px"
                        >
                          {data?.signUps.currentWeek}
                        </Heading>
                      </Box>
                    </Flex>

                    {/* Row 2 */}
                    <Flex w="100%" justifyContent="center" gap={5}>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Users signed up in</p>
                          <p>current month</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#C4F4A5"
                          borderRadius="2px"
                        >
                          {data?.signUps.currentMonth}
                        </Heading>
                      </Box>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Users signed up in</p>
                          <p>last month</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#C4F4A5"
                          borderRadius="2px"
                        >
                          {data?.signUps.lastmonth}
                        </Heading>
                      </Box>
                    </Flex>

                    {/* row 3 */}
                    <Flex w="100%" justifyContent="center">
                      <Box
                        w="200px"
                        h="90px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Total signed up users</p>
                          <p>till date</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#C4F4A5"
                          borderRadius="2px"
                        >
                          {data?.signUps.total}
                        </Heading>
                      </Box>
                    </Flex>
                  </Flex>
                )}

                <Box
                  position="absolute"
                  bottom="5"
                  w="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    w="90%"
                    color="white"
                    bg="#281e52"
                    onClick={() => {
                      onOpen();
                    }}
                  >
                    {" "}
                    Know More{" "}
                  </Button>
                </Box>
              </Box>
              {/* bx-5 */}
              <Box
                w={{ base: "300px", sm: "300px", md: "400px" }}
                height="500px"
                bg="whiteAlpha.800"
                borderRadius="20px"
                margin="10px"
                boxShadow={`rgba(0, 0, 0, 0.35) 0px 5px 15px`}
                position="relative"
              >
                <Flex
                  h="20%"
                  w="100%"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  bg="white"
                  color="black"
                  boxShadow={`rgba(0, 0, 0, 0.35) 0px 5px 15px`}
                  borderTopRadius="20px"
                >
                  <Icon
                    as={MdRepeatOn}
                    color="#5AA6D4"
                    fontSize="4xl"
                    marginBottom="8px"
                  />
                  <Heading size="md">Retention</Heading>
                </Flex>
                {isLoading ? (
                  <Flex
                    h="300px"
                    w="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="#281e52"
                      size="xl"
                    />
                  </Flex>
                ) : (
                  <Flex
                    flexDirection="column"
                    gap={{ base: "20px", md: 5 }}
                    margin="20px"
                    mt="30px"
                  >
                    <Flex w="100%" justifyContent="center" gap={5}>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Users retained in</p>
                          <p>current week</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#A5D2F4"
                          borderRadius="2px"
                        >
                          {data?.retention.currentWeek}
                        </Heading>
                      </Box>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Users retained in</p>
                          <p>last week</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#A5D2F4"
                          borderRadius="2px"
                        >
                          {data?.retention.lastweek}
                        </Heading>
                      </Box>
                    </Flex>

                    {/* Row 2 */}
                    <Flex w="100%" justifyContent="center" gap={5}>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Users retained in</p>
                          <p>current month</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#A5D2F4"
                          borderRadius="2px"
                        >
                          {data?.retention.currentMonth}
                        </Heading>
                      </Box>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Users retained in</p>
                          <p>last month</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#A5D2F4"
                          borderRadius="2px"
                        >
                          {data?.retention.lastmonth}
                        </Heading>
                      </Box>
                    </Flex>

                    {/* row 3 */}
                    <Flex w="100%" justifyContent="center">
                      <Box
                        w="200px"
                        h="90px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Total retained users</p>
                          <p>till date</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#A5D2F4"
                          borderRadius="2px"
                        >
                          {data?.retention.total}
                        </Heading>
                      </Box>
                    </Flex>
                  </Flex>
                )}

                <Box
                  position="absolute"
                  bottom="5"
                  w="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    w="90%"
                    color="white"
                    bg="#281e52"
                    onClick={() => {
                      onOpen();
                    }}
                  >
                    {" "}
                    Know More{" "}
                  </Button>
                </Box>
              </Box>

              {/* bx-6 */}
              <Box
                w={{ base: "300px", sm: "300px", md: "400px" }}
                height="500px"
                bg="whiteAlpha.800"
                borderRadius="20px"
                margin="10px"
                boxShadow={`rgba(0, 0, 0, 0.35) 0px 5px 15px`}
                position="relative"
              >
                <Flex
                  h="20%"
                  w="100%"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  bg="white"
                  color="black"
                  boxShadow={`rgba(0, 0, 0, 0.35) 0px 5px 15px`}
                  borderTopRadius="20px"
                >
                  <Icon
                    as={TbDeviceMobileMessage}
                    color="#F8C78F"
                    fontSize="4xl"
                    marginBottom="8px"
                  />
                  <Heading size="md">Messages Recived</Heading>
                </Flex>
                {isLoading ? (
                  <Flex
                    h="300px"
                    w="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="#281e52"
                      size="xl"
                    />
                  </Flex>
                ) : (
                  <Flex
                    flexDirection="column"
                    gap={{ base: "20px", md: 5 }}
                    margin="20px"
                    mt="30px"
                  >
                    <Flex w="100%" justifyContent="center" gap={5}>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Messages recieved </p>
                          <p>Today</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#F4CFA5"
                          borderRadius="2px"
                        >
                          {data?.messagesReceived.today}
                        </Heading>
                      </Box>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Messages received in </p>
                          <p>current week</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#F4CFA5"
                          borderRadius="2px"
                        >
                          {data?.messagesReceived.currentWeek}
                        </Heading>
                      </Box>
                    </Flex>

                    {/* Row 2 */}
                    <Flex w="100%" justifyContent="center" gap={5}>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Messages received</p>
                          <p>in last month</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#F4CFA5"
                          borderRadius="2px"
                        >
                          {data?.messagesReceived.lastmonth}
                        </Heading>
                      </Box>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Messages received in</p>
                          <p>current month</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#F4CFA5"
                          borderRadius="2px"
                        >
                          {data?.messagesReceived.currentMonth}
                        </Heading>
                      </Box>
                    </Flex>

                    {/* row 3 */}
                    <Flex w="100%" justifyContent="center">
                      <Box
                        w="200px"
                        h="90px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Messages recieved</p>
                          <p>till date</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#F4CFA5"
                          borderRadius="2px"
                        >
                          {data?.messagesReceived.total}
                        </Heading>
                      </Box>
                    </Flex>
                  </Flex>
                )}

                <Box
                  position="absolute"
                  bottom="5"
                  w="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    w="90%"
                    color="white"
                    bg="#281e52"
                    onClick={() => {
                      onOpen();
                    }}
                  >
                    {" "}
                    Know More{" "}
                  </Button>
                </Box>
              </Box>

              {/* bx-7 */}
              <Box
                w={{ base: "300px", sm: "300px", md: "400px" }}
                height="500px"
                bg="whiteAlpha.800"
                borderRadius="20px"
                margin="10px"
                boxShadow={`rgba(0, 0, 0, 0.35) 0px 5px 15px`}
                position="relative"
              >
                <Flex
                  h="20%"
                  w="100%"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  bg="white"
                  color="black"
                  boxShadow={`rgba(0, 0, 0, 0.35) 0px 5px 15px`}
                  borderTopRadius="20px"
                >
                  <Icon
                    as={TbMessage2Share}
                    color="#F88F8F"
                    fontSize="4xl"
                    marginBottom="8px"
                  />
                  <Heading size="md">Messages Sent</Heading>
                </Flex>
                {isLoading ? (
                  <Flex
                    h="300px"
                    w="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="#281e52"
                      size="xl"
                    />
                  </Flex>
                ) : (
                  <Flex
                    flexDirection="column"
                    gap={{ base: "20px", md: 5 }}
                    margin="20px"
                    mt="30px"
                  >
                    <Flex w="100%" justifyContent="center" gap={5}>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Messages sent </p>
                          <p>today</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#F4A5A5"
                          borderRadius="2px"
                        >
                          {data?.messagesSent.today}
                        </Heading>
                      </Box>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Messages sent in </p>
                          <p>current week</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#F4A5A5"
                          borderRadius="2px"
                        >
                          {data?.messagesSent.currentWeek}
                        </Heading>
                      </Box>
                    </Flex>

                    {/* Row 2 */}
                    <Flex w="100%" justifyContent="center" gap={5}>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Messages sent</p>
                          <p>in last month</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#F4A5A5"
                          borderRadius="2px"
                        >
                          {data?.messagesSent.lastmonth}
                        </Heading>
                      </Box>
                      <Box
                        w="160px"
                        h="80px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Messages sent in</p>
                          <p>current month</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#F4A5A5"
                          borderRadius="2px"
                        >
                          {data?.messagesSent.currentMonth}
                        </Heading>
                      </Box>
                    </Flex>

                    {/* row 3 */}
                    <Flex w="100%" justifyContent="center">
                      <Box
                        w="200px"
                        h="90px"
                        bg="white"
                        borderRadius="8px"
                        position="relative"
                        display="flex"
                        justifyContent="center"
                      >
                        <Heading
                          size="xs"
                          position="absolute"
                          top="2"
                          textAlign="center"
                        >
                          <p>Messages Sent</p>
                          <p>till date</p>
                        </Heading>
                        <Heading
                          size="sm"
                          position="absolute"
                          bottom="2"
                          padding="4px"
                          bg="#F4A5A5"
                          borderRadius="2px"
                        >
                          {data?.messagesSent.total}
                        </Heading>
                      </Box>
                    </Flex>
                  </Flex>
                )}

                <Box
                  position="absolute"
                  bottom="5"
                  w="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Button
                    w="90%"
                    color="white"
                    bg="#281e52"
                    onClick={() => {
                      onOpen();
                    }}
                  >
                    {" "}
                    Know More{" "}
                  </Button>
                </Box>
              </Box>
            </SimpleGrid>
          </Flex>
        </chakra.div>
      )}
    </>
  );
}

export default Dashboard;
