import React, { useState } from "react";
import {
  Box,
  Flex,
  chakra,
  Heading,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputRightElement,
  InputGroup,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";

function Login() {
  const toast = useToast();
  const [formData, setFormData] = useState({
    userId: "",
    passWord: "",
  });
  // const [apiKey,setApiKey] = useState("")
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const checkLogedIn = async () => {
    setIsloading(true);
    //
    try {
      const url = "https://milo-api.digest.ai/api/dashboard/login";
      const response = await axios.post(url, {
        username: formData.userId ,
        password: formData.passWord,
      });
      // "milo-testuser-01" "milo-testuser-password-01"
      if (response) {
        setIsloading(false);
        let token = response.data.token;
        localStorage.setItem("key-rampage", token);
        if (token.length > 0) {
          setTimeout(() => {
            window.open("/dashboard", "_self");
          }, 1000);
        }
      }
      toast({
        title: "Success.",
        description: "Successfully logged In.",
        status: "success",
        duration: 8000,
        isClosable: true,
      });
    } catch (error) {
      setIsError(true);
    }
  };

  return (
    <chakra.div w="100%" h="100vh" bg="#281e52">
      <Flex
        w="100%"
        h="5vh"
        boxShadow={`rgba(0, 0, 0, 0.35) 0px 5px 15px`}
        alignItems="center"
        justifyContent="center"
        color="whiteAlpha.700"
      >
        <Box mr="5px">___</Box>
        <Heading color="whiteAlpha.700" fontSize="lg">
          <p>Data Analytics</p>
        </Heading>
        <Box ml="5px">___</Box>
      </Flex>
      <Flex h="60vh" w="100%" alignItems="center" justifyContent="center">
        <Box
          w="350px"
          height="fit-content"
          bg="white"
          display="flex"
          flexDirection="column"
          alignItems="center"
          borderRadius="5px"
        >
          <Heading
            textAlign="center"
            fontSize="xl"
            padding="15px"
            borderBottom="0.5px solid lightgray"
            w="100%"
          >
            Login
          </Heading>
          <FormControl w="80%" mt="4">
            {isError && (
              <Box
                color="red.300"
                fontSize="sm"
                textAlign="center"
                fontWeight="bold"
              >
                Please check the details and try again.
              </Box>
            )}
            <FormLabel>User Name</FormLabel>
            <Input
              type="text"
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  userId: e.target.value,
                }))
              }
            />
            <FormLabel>Password</FormLabel>
            <InputGroup size="md">
              <Input
                type={show ? "text" : "password"}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    passWord: e.target.value,
                  }))
                }
              />
              <InputRightElement width="4.5rem">
                <Button h="1.75rem" size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Button
              w="100%"
              mt="4"
              mb="4"
              color="white"
              bg="#281e52"
              isLoading={isLoading}
              onClick={() => {
                checkLogedIn();
              }}
            >
              Login
            </Button>
          </FormControl>
        </Box>
      </Flex>
    </chakra.div>
  );
}

export default Login;
